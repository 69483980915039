import client from 'apis/client';
import { ContactFormPayload } from 'types/contact';

export const registerContactForm = (payload: ContactFormPayload) =>
  client
    .post(`/api/user/coupons/contact/kitcheniol`, payload)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
