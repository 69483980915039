import clsx from 'clsx';
import { FormInput } from 'types/contact';
import { INPUT_ERROR_MSG } from 'constant/contact';
import { memo } from 'react';

function EmailInput({ isDirty, errors, register }: FormInput) {
  return (
    <div className='input_wrapper'>
      <input
        className={clsx('contact_input', {
          contact_input_error: errors,
        })}
        placeholder='이메일'
        type='email'
        // formNoValidate={false}
        aria-invalid={!isDirty ? undefined : errors ? 'true' : 'false'}
        {...register('email', {
          required: INPUT_ERROR_MSG.email.required,
          pattern: {
            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g,
            message: INPUT_ERROR_MSG.email.pattern,
          },
        })}
      />
      <small
        role='alert'
        className={clsx('invisible text-red xs:text-xs sm:text-sm', {
          contact_error: errors,
        })}
      >
        {errors ? errors.message : '.'}
      </small>
    </div>
  );
}

export default memo(EmailInput);
