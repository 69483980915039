import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { BEST_PRODUCTS } from 'constant/home';

function Home() {
  const navigate = useNavigate();

  return (
    <main className='mb-m-footer w-full lg:mb-d-footer'>
      <article className='flex h-screen w-full flex-col items-center justify-center bg-showroom_mobile bg-cover bg-no-repeat text-white lg:bg-showroomTemp'>
        <h1 className='mb-3 text-center text-2xl font-semibold lg:text-3xl'>
          유러피안 주방가전 구매대행 {isMobile && <br />}전문업체 키치니올
        </h1>
        <BrowserView>
          <span className='text-xl font-normal '>
            구매에서 설치, A/S까지. 주방 가전 구매의 모든 것을 함께 해 드립니다.
          </span>
        </BrowserView>
        <MobileView>
          <span className='text-sm font-normal lg:text-xl'>주방 가전 구매의 모든 것을 함께 해 드립니다.</span>
        </MobileView>
      </article>
      {/* <article className='align-center flex w-full flex-col items-center justify-center lg:h-[682px]'>
        <section className='m-20 h-full lg:h-auto 2xl:w-[1200px]'>
          <h2 className='mt-10 text-base font-semibold mb-30 lg:mt-40 lg:text-2xl'>키치니올 추천 상품</h2>
          <ul className='grid grid-cols-2 gap-x-4 gap-y-8 lg:grid-cols-4'>
            {BEST_PRODUCTS.map((product, idx) => (
              <li key={idx}>
                <img
                  alt={product.alt}
                  src={product.src}
                  className='border-[1px] border-solid border-gray-200'
                />
                <div className='flex flex-col mt-4 ml-8 lg:mt-10 lg:ml-14'>
                  <span className='text-sm font-light text-gray-200 lg:text-base'>{product.brand}</span>
                  <span className='font-medium'>{product.modelName}</span>
                </div>
              </li>
            ))}
          </ul>
          <div className='w-full text-center mt-50 mb-80'>
            <button
              className='h-48 w-156 border-[1px] border-solid border-gray-100 font-medium text-gray-400 lg:h-64 lg:w-[254px]'
              onClick={() => navigate('/ts/dev/products')}
            >
              MORE
            </button>
          </div>
        </section>
      </article> */}
    </main>
  );
}

export default Home;
