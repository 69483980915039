import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { MENU, HOME_URL } from 'constant/header';
import { useScrollYTrigger } from 'hooks/useScrollYTrigger';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import HamburgerButton from 'components/layout/buttons/HamburgerButton';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import CloseWhiteThinButton from 'components/layout/buttons/CloseWhiteThinButton';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { scrollY } = useScrollYTrigger();
  const [menuTitle, setMenuTitle] = useState('');
  const [isMainUrl, setIsMainUrl] = useState(false);
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

  const clickMobileNavHandler = () => {
    setIsOpenMobileNav((prev) => !prev);
  };

  useEffect(() => {
    if (location.pathname === HOME_URL) {
      setIsMainUrl(true);
    }
    if (location.pathname !== HOME_URL) {
      setIsMainUrl(false);
      const [_, [__, currentMenu]] = Object.entries(MENU).filter(([key, menu]) =>
        location.pathname.includes(menu.url)
      );
      setMenuTitle(currentMenu.lowercase);
    }
  }, [location]);

  useEffect(() => {
    if (isOpenMobileNav) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpenMobileNav]);

  return (
    <>
      <BrowserView>
        <header
          className={clsx('header h-d-header', {
            'bg-black': (scrollY > 10 && isMainUrl) || !isMainUrl,
            'box-shadow-none': location.pathname === HOME_URL && scrollY < 11,
          })}
        >
          <div className='flex w-content items-center justify-between'>
            <img
              alt='kitcheniol'
              src='/images/logo/default_white_logo.svg'
              className='h-d-header-logo w-d-header-logo cursor-pointer'
              onClick={() => navigate(HOME_URL)}
            />
            <nav className='w-full'>
              <ul className='flex w-full justify-end text-white'>
                {Object.entries(MENU).map(([key, menu], idx) => {
                  if (key === MENU.Home.lowercase) return null;
                  return (
                    <li className='header_menu_li' key={idx}>
                      <NavLink
                        to={menu.url}
                        className={({ isActive }) =>
                          isActive ? 'header_menu_nav_active ' : 'header_menu_nav'
                        }
                      >
                        {menu.uppercase}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </header>
      </BrowserView>

      <MobileView>
        <header
          className={clsx('header h-m-header justify-center bg-black', {
            'box-shadow-none': isMobile,
          })}
        >
          {location.pathname === HOME_URL && (
            <img
              alt='kitcheniol'
              src='/images/logo/default_white_logo.svg'
              className={clsx('h-m-header-logo w-m-header-logo', {
                hidden: isOpenMobileNav,
              })}
              onClick={() => navigate(HOME_URL)}
            />
          )}
          {location.pathname !== HOME_URL && (
            <h1
              className={clsx('text-xl font-semibold text-white', {
                hidden: isOpenMobileNav,
              })}
            >
              {menuTitle}
            </h1>
          )}

          <div className={clsx('absolute right-0 z-[20] flex items-center')}>
            {isOpenMobileNav && <CloseWhiteThinButton mobileNavHandler={clickMobileNavHandler} />}
            {!isOpenMobileNav && <HamburgerButton mobileNavHandler={clickMobileNavHandler} />}
          </div>
        </header>

        {isOpenMobileNav && (
          <>
            <div className='fixed z-[2]  flex h-full w-screen bg-black-800 opacity-70' />
            <div className={isOpenMobileNav ? 'mobile_nav_animation' : ''}>
              <nav className='w-full'>
                <ul>
                  {Object.entries(MENU).map(([key, menu], idx) => {
                    return (
                      <li className='mobile_header_menu_li' key={idx}>
                        <NavLink
                          to={menu.url}
                          className={({ isActive }) =>
                            isActive ? 'header_menu_nav_active ' : 'header_menu_nav text-gray-300'
                          }
                          end
                          onClick={() => setIsOpenMobileNav(false)}
                        >
                          {menu.uppercase}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </>
        )}
      </MobileView>
    </>
  );
}

export default Header;
