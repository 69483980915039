import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'features';
import { watchContactActions } from 'features/contact/saga';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { all, call } from 'redux-saga/effects';

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

function* watcherSaga() {
  yield all([watchContactActions()]);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(sagaMiddleware),

  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(watcherSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
