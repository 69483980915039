import { AxiosResponse } from 'axios';

export const RESPONSE_ERROR = 'RESPONSE_ERROR' as const;
export interface ResponseData {
  succeeded: boolean;
  message: null | string;
  data: any;
}

export interface RequestApi {
  (params: any): Promise<void | AxiosResponse<any, any>>;
}
