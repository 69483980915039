import contactActions from 'features/contact/slice';
import * as ContactAPI from 'apis/contact';
import { ContactFormPayload } from 'types/contact';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

export function* registerContactSaga(action: { payload: ContactFormPayload }) {
  const { registerContactFormSuccess, registerContactFormFailure } = contactActions;
  const param = action.payload;

  try {
    // call은 미들웨어에게 함수와 인자들을 실행하라는 명령
    const response: AxiosResponse = yield call(ContactAPI.registerContactForm, param);
    // put은 dispatch 를 뜻한다.
    yield put(registerContactFormSuccess(response.data));
  } catch (error) {
    yield put(registerContactFormFailure(error));
  }
}

export function* watchContactActions() {
  // 만약 registerContact액션 (패턴이라고도 하는데)이 감지되면, registerContactSaga 호출한다.
  yield takeLatest(contactActions.registerContactForm, registerContactSaga);
}
