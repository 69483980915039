import { ResponseData, RESPONSE_ERROR } from 'types/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactFormPayload, ContactState } from 'types/contact';
import { CONTACT_TYPES_PLACEHOLDER } from 'constant/contact';

const initialState: ContactState = {
  completedContact: null,
  isLoading: false,
  // error: null,
  isDropdownContactType: false,
  selectedContactType: CONTACT_TYPES_PLACEHOLDER,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    registerContactFormSuccess: (state, action: PayloadAction<ResponseData>) => {
      state.isLoading = false;
      state.completedContact = action.payload;
    },
    registerContactFormFailure: (state, { payload: error }) => {
      state.isLoading = false;
      state.completedContact = RESPONSE_ERROR;
    },
    registerContactForm: (state, action: PayloadAction<ContactFormPayload>) => {
      state.isLoading = true;
    },
    resetContactForm: () => initialState,
    setIsDropdownContactType: (state, { payload }) => {
      state.isDropdownContactType = payload;
    },
    setSelectedContactType: (state, { payload }) => {
      state.selectedContactType = payload;
    },
  },
});

export default contactSlice.actions;
export const contactReducer = contactSlice.reducer;
