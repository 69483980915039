import clsx from 'clsx';
import { memo } from 'react';
import { FormInput } from 'types/contact';
import { INPUT_ERROR_MSG } from 'constant/contact';

function PhoneInput({ isDirty, errors, register }: FormInput) {
  return (
    <div className='input_wrapper'>
      <input
        className={clsx('contact_input', {
          contact_input_error: errors,
        })}
        placeholder={INPUT_ERROR_MSG.phone.placeholder}
        type='tel'
        // pattern='\d*'
        // min={0}
        aria-invalid={!isDirty ? undefined : errors ? 'true' : 'false'}
        {...register('phone', {
          required: INPUT_ERROR_MSG.phone.required,
          pattern: {
            value: /^[0-9 ]+$/g,
            message: INPUT_ERROR_MSG.phone.pattern,
          },
          maxLength: {
            value: 11,
            message: INPUT_ERROR_MSG.phone.maxLength,
          },
          minLength: {
            value: 10,
            message: INPUT_ERROR_MSG.phone.minLength,
          },
        })}
      />
      <small
        role='alert'
        className={clsx('invisible text-red xs:text-xs sm:text-sm', {
          contact_error: errors,
        })}
      >
        {errors ? errors.message : '.'}
      </small>
    </div>
  );
}

export default memo(PhoneInput);
