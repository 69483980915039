import clsx from 'clsx';
import { memo } from 'react';
import { FormInput } from 'types/contact';
import { INPUT_ERROR_MSG } from 'constant/contact';

function NameInput({ isDirty, errors, register }: FormInput) {
  return (
    <div className='input_wrapper'>
      <input
        className={clsx('contact_input col-span-2 ', {
          contact_input_error: errors,
        })}
        placeholder={INPUT_ERROR_MSG.name.placeholder}
        type='text'
        aria-invalid={!isDirty ? undefined : errors ? 'true' : 'false'}
        {...register('name', {
          required: INPUT_ERROR_MSG.name.required,
          pattern: {
            value: /^[ㄱ-ㅎ|가-힣|a-z|A-Z ]+$/g,
            message: INPUT_ERROR_MSG.name.pattern,
          },
        })}
      />

      <small
        role='alert'
        className={clsx('invisible text-red xs:text-xs sm:text-sm', {
          contact_error: errors,
        })}
      >
        {errors ? errors.message : '.'}
      </small>
    </div>
  );
}

export default memo(NameInput);
