import clsx from 'clsx';
import OneButtonModal from 'components/common/OneButtonModal';
import { INPUT_ERROR_MSG, PRIVACY_CONTENT } from 'constant/contact';
import { memo, useState } from 'react';
import { FormInput } from 'types/contact';

function PrivacyCheckBox({ isDirty, errors, register }: FormInput) {
  const [isPrivacyModal, setIsPrivacyModal] = useState(false);

  const closeModal = () => {
    setIsPrivacyModal(false);
  };
  return (
    <div className='col-span-2 py-2 pr-4'>
      {isPrivacyModal && (
        <OneButtonModal modalContent={PRIVACY_CONTENT} isModal={isPrivacyModal} closeModal={closeModal} />
      )}

      <div className='contact-privacy_checkBox text-gray-200'>
        <input
          className='hidden'
          type='checkbox'
          id='privacy'
          {...register('isAgree', {
            required: { value: true, message: INPUT_ERROR_MSG.privacy.required },
          })}
        />
        <label
          className={clsx('custom_checkbox_mobile', {
            contact_input_error: errors,
          })}
          htmlFor='privacy'
        />
        <label htmlFor='privacy' className='text-sm lg:text-base'>
          문의를 위해 수집한 <u onClick={() => setIsPrivacyModal(true)}>개인정보의 이용 및 제3자 제공</u>에
          동의합니다.
        </label>
      </div>
      <small
        role='alert'
        className={clsx('invisible mt-1 block text-red xs:text-xs sm:text-sm', {
          contact_error: errors,
        })}
      >
        {errors ? errors.message : '.'}
      </small>
    </div>
  );
}

export default memo(PrivacyCheckBox);
