import { MENU, HOME_URL } from 'constant/header';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollTop() {
  const { pathname } = useLocation();

  const isHideScroll = () => {
    return pathname === `${HOME_URL}${MENU.Home.url}` || pathname === `${HOME_URL}${MENU.Contact.url}`;
  };
  useEffect(() => {
    const scrollController = document.getElementById('scroll_controller');

    if (isHideScroll()) {
      if (!scrollController) {
        const newScrollController = document.createElement('style');
        newScrollController.innerHTML = `body::-webkit-scrollbar {display: none;}`;
        newScrollController.id = 'scroll_controller';
        document.head.appendChild(newScrollController);
      }
    } else {
      if (scrollController) {
        // scrollController.innerHTML = `body::-webkit-scrollbar {display: block;}`;
        scrollController.parentNode?.removeChild(scrollController);
      }
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollTop;
