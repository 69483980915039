import clsx from 'clsx';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ContactTypeInput } from 'types/contact';
import contactActions from 'features/contact/slice';
import {
  CONTACT_TYPES,
  CONTACT_TYPES_PLACEHOLDER,
  CONTACT_TYPE_SELECT_BOX,
  INPUT_ERROR_MSG,
} from 'constant/contact';
import { memo, MouseEventHandler, useEffect, useRef } from 'react';

function ContactTypeSelect({ errors, register, setValue }: ContactTypeInput) {
  const dispatch = useAppDispatch();
  const { setIsDropdownContactType, setSelectedContactType } = contactActions;
  const contactTypeUl = useRef<HTMLUListElement>(null);

  const { isDropdownContactType, selectedContactType } = useAppSelector(
    ({ contactReducer }) => ({
      isDropdownContactType: contactReducer.isDropdownContactType,
      selectedContactType: contactReducer.selectedContactType,
    }),
    shallowEqual
  );

  const contactTypeHandler = () => {
    dispatch(setIsDropdownContactType(!isDropdownContactType));
  };

  const selectContactTypeHandler: MouseEventHandler<HTMLLIElement> = ({ currentTarget }) => {
    dispatch(setSelectedContactType(currentTarget.innerText));
    dispatch(setIsDropdownContactType(false));
  };

  useEffect(() => {
    if (contactTypeUl.current && isDropdownContactType) {
      contactTypeUl.current.classList.add('contact-type_ul');
    }
  }, [isDropdownContactType]);

  useEffect(() => {
    const element = document.getElementById('contactType');
    if (element && selectedContactType !== CONTACT_TYPES_PLACEHOLDER) {
      setValue('contactType', selectedContactType, { shouldValidate: true });
    }
  }, [selectedContactType]);

  return (
    <div className='input_wrapper'>
      <select
        className='html-select'
        {...register('contactType', { required: INPUT_ERROR_MSG.contactType.required })}
        name='contactType'
        id='contactType'
      >
        <option value=''></option>
      </select>

      <div
        className={clsx('contact-type_select text-gray-400', {
          'contact-type_select-active contact-type_selected': isDropdownContactType,
          'contact-type_selected': selectedContactType !== CONTACT_TYPES_PLACEHOLDER,
          contact_input_error: errors,
        })}
      >
        <button
          id={CONTACT_TYPE_SELECT_BOX}
          className='contact-type_label'
          type='button'
          onClick={contactTypeHandler}
        >
          {selectedContactType}
        </button>

        {isDropdownContactType && (
          <ul ref={contactTypeUl}>
            {CONTACT_TYPES.map(({ label, value }, idx) => (
              <li className='contact-type_li' value={label} key={idx} onClick={selectContactTypeHandler}>
                {value}
              </li>
            ))}
          </ul>
        )}
      </div>
      <small
        role='alert'
        className={clsx('invisible text-red xs:text-xs sm:text-sm', {
          contact_error: errors,
        })}
      >
        {errors ? errors.message : '.'}
      </small>
    </div>
  );
}

export default memo(ContactTypeSelect);
