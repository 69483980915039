import { BrowserView, MobileView } from 'react-device-detect';
import { FOOTER } from 'constant/footer';
import BlogButton from 'components/layout/buttons/BlogButton';
import YoutubeButton from 'components/layout/buttons/YoutubeButton';
import InstagramButton from 'components/layout/buttons/InstagramButton';

function Footer() {
  return (
    <>
      <BrowserView className='footer h-d-footer w-full'>
        {/* <BrowserView className='footer absolute h-d-footer w-full'> */}
        <footer className='flex h-full w-content items-center'>
          <div className='flex w-full justify-between '>
            <aside>
              <img
                alt='kitcheniol'
                src='/images/logo/default_white_logo.svg'
                className='mb-3 h-d-footer-logo w-d-footer-logo'
              />
              <span className='py-1 text-sm'>
                {FOOTER.address} | {FOOTER.businessRegistrationNumber} | {FOOTER.personalInformationManager} |{' '}
                {FOOTER.companyPhoneNumber} | {FOOTER.companyEmail}
              </span>
            </aside>

            <aside className='flex'>
              <InstagramButton />
              <BlogButton />
              <YoutubeButton />
            </aside>
          </div>
        </footer>
      </BrowserView>

      <MobileView className='footer h-m-footer border-t border-gray-400/20'>
        <footer className='flex h-full flex-col items-center xs:justify-evenly sm:justify-center'>
          <aside className='flex w-full max-w-[420px] items-center justify-around xs:flex-col-reverse sm:flex-row'>
            <span className='text-sm'>(주)키치니올</span>
            <div>
              <InstagramButton />
              <BlogButton />
              <YoutubeButton />
            </div>
          </aside>
          <aside className='flex flex-col'>
            <span className='text-2xs sm:mb-1 sm:text-xs'>
              {FOOTER.companyPhoneNumber}
              <br className='xs:block sm:hidden' />
              <span className='hidden sm:inline'> | </span>
              {FOOTER.personalInformationManager}
            </span>

            <span className='text-2xs sm:mb-1 sm:text-xs'>
              {FOOTER.businessRegistrationNumber}
              <br className='xs:block sm:hidden' />
              <span className='hidden sm:inline'> | </span>
              {FOOTER.companyEmail}
            </span>

            <span className='xs:text-2xs sm:text-xs'>주소: {FOOTER.address}</span>
          </aside>
        </footer>
      </MobileView>
    </>
  );
}

export default Footer;
