import { handlerProps } from 'types/handler';

function HamburgerButton({ mobileNavHandler }: handlerProps) {
  return (
    <button className='text-white hover:text-gray-200' onClick={mobileNavHandler}>
      <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M14 17H33' stroke='white' strokeLinecap='round' className='stroke-current' />
        <path d='M14 24H33' stroke='white' strokeLinecap='round' className='stroke-current' />
        <path d='M14 31H33' stroke='white' strokeLinecap='round' className='stroke-current' />
      </svg>
    </button>
  );
}

export default HamburgerButton;
