export const HOME_URL = '/';
export const MENU = {
  Home: {
    url: '',
    uppercase: 'HOME',
    lowercase: 'Home',
  },
  // Products: {
  //   url: 'products',
  //   uppercase: 'PRODUCTS',
  //   lowercase: 'Products',
  // },

  // Showroom: {
  //   url: 'showroom',
  //   uppercase: 'SHOWROOM',
  //   lowercase: 'Showroom',
  // },
  AboutUs: {
    url: 'about-us',
    uppercase: 'ABOUT US',
    lowercase: 'About us',
  },

  Contact: {
    url: 'contact',
    uppercase: 'CONTACT',
    lowercase: 'Contact',
  },
};
