import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export function useScrollYTrigger() {
  const [scrollY, setScrollY] = useState<Number>(0);
  const delay = 10;

  const listener = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(listener, delay));
    return () => {
      window.removeEventListener('scroll', debounce(listener));
    };
  });

  return {
    scrollY,
  };
}
