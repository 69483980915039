import { handlerProps } from 'types/handler';

function CloseWhiteThinButton({ mobileNavHandler }: handlerProps) {
  return (
    <button className='text-white hover:text-gray-200' onClick={mobileNavHandler}>
      <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M30.5 17L17 30.5' stroke='white' strokeLinecap='round' className='stroke-current' />
        <path d='M17 17L30.5 30.5' stroke='white' strokeLinecap='round' className='stroke-current' />
      </svg>
    </button>
  );
}

export default CloseWhiteThinButton;
