import { useEffect } from 'react';
import { ModalProps } from 'types/common';
import CloseBlackBoldButton from 'components/layout/buttons/CloseBlackBoldButton';

function OneButtonModal({ modalContent, buttonText = '확인', isModal, closeModal }: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModal]);

  return (
    <section
      id='popup-modal'
      // tabindex='-1'
      className='fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black-opacity-60'
    >
      <article className='relative flex w-[17rem] flex-row-reverse items-center justify-between rounded-lg bg-white shadow lg:min-h-[16rem] lg:w-[30rem]'>
        <div className='absolute right-0 self-start p-1'>
          <CloseBlackBoldButton closeModal={closeModal} />
        </div>

        <div className='my-6 h-full w-full'>
          <div className='flex flex-col items-center justify-around overflow-y-hidden px-4 lg:px-6'>
            <span className='my-4 h-full whitespace-pre-line text-center text-base font-medium text-gray-500'>
              {modalContent}
            </span>
            <button
              data-modal-toggle='popup-modal'
              type='button'
              className='my-4 h-10 w-2/3 rounded-sm bg-gray-200 text-center text-sm font-semibold text-white hover:bg-gray-300 lg:h-12'
              onClick={() => closeModal()}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </article>
    </section>
  );
}

export default OneButtonModal;
