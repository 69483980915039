import { MENU } from 'constant/header';
import Home from 'components/home/Home';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import AboutUs from 'components/about/AboutUs';
import Contact from 'components/contact/Contact';
import Showroom from 'components/showroom/Showroom';
import Products from 'components/products/Products';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function Template() {
  const location = useLocation();

  return (
    <>
      <Header />
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.key}
          timeout={600}
          classNames={{
            enter: 'page-enter-active',
            enterActive: 'page-enter',
            enterDone: 'opacity-100',
            exit: 'page-exit',
            exitActive: 'page-exit-active',
          }}
        >
          <Routes location={location}>
            <Route path={MENU.Home.url} element={<Home />} />
            {/* <Route path={MENU.Products.url} element={<Products />} /> */}
            {/* <Route path={MENU.Showroom.url} element={<Showroom />} /> */}
            <Route path={MENU.AboutUs.url} element={<AboutUs />} />
            <Route path={MENU.Contact.url} element={<Contact />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </>
  );
}

export default Template;
