export const CONTACT_TYPES_PLACEHOLDER = '문의 유형';
export const CONTACT_TYPES = [
  { label: 'personal', value: '개인' },
  { label: 'business', value: '기업' },
  { label: 'other', value: '기타' },
] as const;

export const CONTACT_TYPE_SELECT_BOX = 'contactTypeSelectBox';

export const INPUT_ERROR_MSG = {
  name: {
    placeholder: '이름',
    required: '이름을 확인해주세요.',
    pattern: '한글 혹은 영어 이름만 가능합니다.',
  },
  phone: {
    placeholder: '연락처(숫자만 입력)',
    required: '연락처를 확인해주세요.',
    pattern: '연락처는 숫자만 입력해주세요',
    minLength: '연락처를 다시 확인해주세요.',
    maxLength: '연락처를 다시 확인해주세요.',
  },
  email: {
    required: '이메일을 확인해주세요.',
    pattern: '이메일이 형식에 맞지 않습니다.',
  },
  contactType: {
    required: '문의 유형을 선택해주세요.',
  },
  content: {
    required: '문의 내용을 작성해주세요.',
    minLength: '10자 이상 작성해주세요.',
  },
  privacy: {
    required: '개인정보의 이용 및 제3자 제공에 동의해주세요.',
  },
} as const;

export const PRIVACY_CONTENT =
  '개인정보의 수집‧이용 목적 \n - 견적문의 답변 및 상담 \n\n 수집하려는 개인정보의 항목 \n - 이름, 연락처, 이메일 \n\n 개인정보의 보유 및 이용 기간 \n- 내부 방침 및 기타 관련 법령에 따라 일정 기간 저장 후 파기';
