import Template from 'components/layout/Template';
import ScrollTop from 'components/common/ScrollTop';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function Router() {
  return (
    <BrowserRouter>
      <ScrollTop />

      <Routes>
        <Route path='/*' element={<Template />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
