function YoutubeButton() {
  return (
    <button
      // className='cursor-default text-gray-400'
      className='text-gray-400 hover:text-gray-300'
      onClick={() => window.open('https://www.youtube.com/@kitcheniol_official', '_black')}
    >
      <svg
        className='xs:h-12 xs:w-12 sm:h-14 sm:w-14'
        viewBox='0 0 48 49'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24.436' r='15.5' stroke='#959595' className='stroke-current' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M31.3899 18.2279C32.199 18.4457 32.8369 19.0837 33.0547 19.8928C33.4593 21.3709 33.4437 24.4516 33.4437 24.4516C33.4437 24.4516 33.4437 27.5168 33.0547 28.995C32.8369 29.804 32.199 30.442 31.3899 30.6598C29.9117 31.0488 23.9992 31.0488 23.9992 31.0488C23.9992 31.0488 18.1022 31.0488 16.6085 30.6442C15.7994 30.4264 15.1615 29.7885 14.9437 28.9794C14.5547 27.5168 14.5547 24.4361 14.5547 24.4361C14.5547 24.4361 14.5547 21.3709 14.9437 19.8928C15.1615 19.0837 15.815 18.4302 16.6085 18.2123C18.0867 17.8234 23.9992 17.8234 23.9992 17.8234C23.9992 17.8234 29.9117 17.8234 31.3899 18.2279ZM27.0339 24.4359L22.1172 27.2677V21.6041L27.0339 24.4359Z'
          fill='#B3B3B3'
          className='fill-current'
        />
      </svg>
    </button>
  );
}
export default YoutubeButton;
