import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { MODAL_MESSAGE } from 'constant/modal';
import { ContactFormValues } from 'types/contact';
import contactActions from 'features/contact/slice';
import NameInput from 'components/contact/form/NameInput';
import PhoneInput from 'components/contact/form/PhoneInput';
import EmailInput from 'components/contact/form/EmailInput';
import OneButtonModal from 'components/common/OneButtonModal';
import FullScreenSpinner from 'components/common/FullScreenSpinner';
import ContentTextArea from 'components/contact/form/ContentTextArea';
import PrivacyCheckBox from 'components/contact/form/PrivacyCheckBox';
import ContactTypeSelect from 'components/contact/form/ContactTypeSelect';
import { CONTACT_TYPES_PLACEHOLDER, CONTACT_TYPE_SELECT_BOX } from 'constant/contact';

function Contact() {
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = useForm<ContactFormValues>();
  const dispatch = useAppDispatch();
  const { registerContactForm, setIsDropdownContactType, setSelectedContactType } = contactActions;

  const [isModal, setIsModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const { isLoading, completedContact, isDropdownContactType } = useAppSelector(
    ({ contactReducer }) => ({
      isLoading: contactReducer.isLoading,
      completedContact: contactReducer.completedContact,
      isDropdownContactType: contactReducer.isDropdownContactType,
    }),
    shallowEqual
  );

  const backgroundClickHandler: MouseEventHandler<HTMLElement> = (e) => {
    const clickedElement = e.target as HTMLInputElement;
    if (clickedElement.id === CONTACT_TYPE_SELECT_BOX) return;
    if (!isDropdownContactType) return;
    dispatch(setIsDropdownContactType(false));
  };

  const submitContactContents: SubmitHandler<ContactFormValues> = (data) => {
    const { isAgree, ...params } = data;
    dispatch(registerContactForm(params));
  };

  const checkCompletedContact = useCallback(() => {
    if (completedContact) {
      if (completedContact.succeeded) {
        setModalContent(MODAL_MESSAGE.contact.succeeded);
        setIsModal(true);
      }
      if (!completedContact.succeeded) {
        completedContact.message
          ? setModalContent(completedContact.message)
          : setModalContent(MODAL_MESSAGE.contact.failed);
        setIsModal(true);
      }
    }
  }, [completedContact]);

  useEffect(() => {
    checkCompletedContact();
  }, [checkCompletedContact]);

  const closeModal = () => {
    dispatch(contactActions.resetContactForm());
    dispatch(setSelectedContactType(CONTACT_TYPES_PLACEHOLDER));
    reset();
    setIsModal(false);
  };

  return (
    // <main className='w-full pt-m-header lg:bg-gray-500 lg:pt-d-header'>
    <main className='mb-m-footer w-full pt-m-header lg:mb-d-footer lg:bg-gray-500 lg:pt-d-header'>
      {isLoading ? <FullScreenSpinner /> : null}
      {isModal && <OneButtonModal modalContent={modalContent} isModal={isModal} closeModal={closeModal} />}
      <section
        className='flex h-m-main min-h-[1000px] flex-col items-center justify-center text-white lg:h-d-main'
        onClick={backgroundClickHandler}
      >
        <article className='flex flex-col justify-center xs:w-64 sm:w-auto'>
          <h1 className='mb-3 hidden text-5xl font-bold lg:block'>CONTACT</h1>
          <dl className='lg:mt-0 lg:mb-16'>
            <dd className='py-10 text-center text-gray-200 xs:text-[0.8rem] sm:text-base lg:py-0 lg:text-lg'>
              제품 견적문의, 사업 제안 등 무엇이든 문의주시면
              {isMobile && <br />} 신속하고 친절하게 상담해 드리겠습니다!
            </dd>
          </dl>

          <form
            className='mx-6 gap-y-1 gap-x-6 lg:mx-0 lg:grid lg:w-[620px] lg:grid-cols-2 '
            onSubmit={handleSubmit(submitContactContents)}
            noValidate
          >
            <NameInput isDirty={isDirty} errors={errors.name} register={register} />
            <PhoneInput isDirty={isDirty} errors={errors.phone} register={register} />
            <EmailInput isDirty={isDirty} errors={errors.email} register={register} />
            <ContactTypeSelect errors={errors.contactType} register={register} setValue={setValue} />
            <ContentTextArea isDirty={isDirty} errors={errors.content} register={register} />
            <PrivacyCheckBox isDirty={isDirty} errors={errors.isAgree} register={register} />

            <button
              className='col-span-2 mx-auto mt-10 mb-20 block h-16 border hover:bg-gray-400/50 active:bg-gray-400/50 active:font-semibold disabled:opacity-50 xs:w-44 sm:w-64 lg:mb-12'
              type='submit'
              disabled={isSubmitting}
            >
              SUBMIT
            </button>
          </form>
        </article>
      </section>
    </main>
  );
}

export default Contact;
