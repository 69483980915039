import LINK from 'constant/urls';
import { BrowserView, MobileView } from 'react-device-detect';

function AboutUs() {
  return (
    <main className='mb-m-footer w-full pt-m-header lg:mb-d-footer lg:pt-d-header'>
      <section className='hidden h-48 w-full bg-aboutUsBanner bg-cover bg-no-repeat text-white lg:block'>
        <article className='flex h-full w-full items-center justify-center'>
          <h1 className='text-3xl font-bold'>ABOUT US</h1>
        </article>
      </section>

      <section>
        <BrowserView>
          <article className='flex h-[30rem] w-full items-center justify-center bg-gray-500  '>
            <div className='flex flex-col justify-between lg:w-content lg:flex-row'>
              <h1 className='text-4xl text-gray-400'>Kitcheniol</h1>
              <div>
                <h6 className='my-2 text-xl font-bold text-white'>여러분만의 품격있는 주방 인테리어를 함께합니다.</h6>
                <p className='break-keep mt-6 w-[63rem] text-base leading-9 text-gray-100'>
                  안녕하세요. 유럽 주방 가전 구매대행 전문업체 키치니올입니다. 구매 과정부터 사후 관리까지 너무나
                  까다롭지만, 하나쯤 꼭 갖고 싶은 해외 가전! <br />
                  키치니올에서는 해외 가전제품의 구매, 설치, A/S를 간편하게 해 드립니다. <br />
                  키치니올은 해외 업체들과 직접 컨택하여 믿을 수 있는 제품만을 선보이고 있습니다. 유니크하고 트렌디한
                  제품으로 고객 여러분들의 눈과 손, 몸과 마음에 최상의 만족을 제공해 드릴 것을 약속드립니다.
                  <br /> 저희 키치니올 홈페이지를 방문해 주셔서 감사합니다.
                </p>
              </div>
            </div>
          </article>

          <article className='relative flex h-full w-full flex-col items-center overflow-x-hidden bg-black py-20'>
            <div className='w-content'>
              <h2 className='text-center text-3xl font-semibold text-white'>키치니올을 선택해야 하는 이유</h2>
              <div className='gird-rows-3 grid grid-cols-2 py-28'>
                <aside className='flex justify-end'>
                  <img src='/images/aboutUs/quality.png' alt='quality' />
                </aside>
                <aside className='flex flex-col justify-center p-20 text-white'>
                  <h6 className='text-3xl'>Quality</h6>
                  <hr className='absolute mb-8 w-full' />
                  <p className='break-keep mt-8 text-lg tracking-tighter text-gray-100'>
                    유럽 각지의 가전 업체에서 현지 생산한 정품만을 직접 계약, 수입하여 유럽에서와 똑같은 고품질을 누리실
                    수 있습니다.
                  </p>
                </aside>
                <aside className='flex flex-col justify-center p-20 text-white '>
                  <h6 className='text-3xl'>Delivery · Installation</h6>
                  <hr className='absolute -left-[15.2rem] w-1/2 pt-[2rem]' />
                  <p className='break-keep mt-8 text-lg text-gray-100'>
                    키치니올만의 검증된 방법으로 고객 여러분의 댁까지 정확하게, 안전히 운송하여 꼼꼼하게 설치를 진행해
                    드립니다.
                  </p>
                </aside>
                <aside>
                  <img src='/images/aboutUs/delivery_install.png' alt='delivery and installation' />
                </aside>
                <aside className='flex justify-end'>
                  <img src='/images/aboutUs/as.png' alt='as' />
                </aside>
                <aside className='flex flex-col justify-center p-20 text-white'>
                  <h6 className='text-3xl'>A/S</h6>
                  <hr className='absolute mb-16 w-full' />
                  <p className='break-keep mt-8 text-lg tracking-tighter text-gray-100'>
                    해외 구매 제품은 A/S가 어렵다? 키치니올은 다릅니다. 독자적인 사후관리 서비스를 통해 A/S까지
                    확실하게! 더 이상 A/S 때문에 해외 가전제품 구매를 망설이지 마세요!
                  </p>
                </aside>
              </div>
            </div>
          </article>
        </BrowserView>

        <MobileView>
          <section className='flex h-[650px] w-full items-center justify-center bg-gray-500 bg-aboutUsBanner_mobile bg-cover bg-no-repeat'>
            <div className='flex h-full flex-col justify-evenly xs:px-3 sm:px-8'>
              <h1 className='text-xl text-gray-300 '>Kitcheniol</h1>
              <h6 className='my-2 font-bold text-white xs:text-xl sm:text-2xl'>
                여러분만의 품격있는 <br /> 주방 인테리어를 함께합니다.
              </h6>
              <p className='break-keep text-base leading-8 text-gray-100 lg:w-[996px]'>
                안녕하세요. <br />
                유럽 주방 가전 구매대행 전문업체 키치니올입니다. 구매 과정부터 사후 유지까지 너무나 까다로운, 그러면서도
                꼭 가지고 싶은 해외 가전제품! 키치니올에서는 이런 해외 가전제품의 구매, 설치, A/S를 간편하게 해
                드립니다. 키치니올은 해외 업체들과 직접 컨택하여 믿을 수 있는 제품만을 선보입니다. 키치니올은 유니크하고
                트렌디한 제품으로 고객 여러분들의 눈과 손, 몸과 마음에 최상의 만족을 제공해드리고자 노력하고 있습니다.
                저희 키치니올 홈페이지를 방문해 주셔서 감사합니다.
              </p>
            </div>
          </section>

          <section className='flex w-full flex-col items-center bg-black pt-16 pb-4'>
            <h2 className='text-white xs:text-xl sm:text-2xl'>키치니올을 선택해야 하는 이유</h2>

            <article className='flex flex-col items-center py-8 px-8'>
              <div className='relative my-8'>
                <h6 className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-2xl font-semibold text-white'>
                  Quality
                </h6>
                <img className='w-full align-middle' src='/images/aboutUs/quality_mobile.png' alt='quality' />
              </div>
              <p className='break-keep py-2 text-center text-gray-100'>
                유럽 각지의 가전 업체에서 현지 생산한 정품만을 직접 계약, 수입하여 유럽에서와 똑같은 고품질을 누리실 수
                있습니다.
              </p>
            </article>

            <hr className='mb-30 w-full border-gray-500' />

            <article className='flex flex-col items-center py-8 px-8'>
              <div className='relative my-8 text-center'>
                <h6 className='absolute top-1/2 left-1/2 w-full -translate-y-2/4 -translate-x-2/4 text-2xl font-semibold  text-white'>
                  Delivery · Installation
                </h6>
                <img
                  className='w-full align-middle'
                  src='/images/aboutUs/delivery_install_mobile.png'
                  alt='delivery and installation'
                />
              </div>
              <p className='break-keep py-2 text-center text-gray-100'>
                키치니올만의 검증된 방법으로 고객 여러분의 댁까지 정확하게, 안전히 운송하여 꼼꼼하게 설치를 진행해
                드립니다.
              </p>
            </article>

            <hr className='mb-30 w-full border-gray-500' />

            <article className='flex flex-col items-center py-8 px-8'>
              <div className='relative my-8'>
                <h6 className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-2xl font-semibold text-white'>
                  A/S
                </h6>
                <img className='w-full align-middle' src='/images/aboutUs/as_mobile.png' alt='as' />
              </div>
              <p className='break-keep py-2 text-center text-gray-100'>
                해외 구매 제품은 A/S가 어렵다? <br />
                키치니올은 다릅니다! <br /> 국내 제품과 똑같이 A/S를 책임져 드립니다.
              </p>
            </article>
          </section>
        </MobileView>

        <section className='flex h-[408px] w-full items-center justify-center bg-white'>
          <div className='flex h-full w-content flex-col items-center justify-evenly lg:justify-center'>
            <h2 className='text-2xl font-semibold text-gray-500 lg:text-3xl  lg:text-gray-400'>Brands</h2>
            <div className='flex h-1/2 flex-col justify-between lg:w-[36rem] lg:flex-row lg:items-center'>
              <button
                className='flex h-[5.5rem] w-48 items-center justify-center border border-solid border-gray-200 lg:mx-5 lg:h-32 lg:w-64'
                onClick={() => window.open(LINK.elica, '_black')}
              >
                <img className='w-28 lg:w-36' src='/images/aboutUs/elica.png' alt='elica' />
              </button>
              <button
                className='flex h-[5.5rem] w-48 items-center justify-center border border-solid border-gray-200 lg:mx-5 lg:h-32 lg:w-64'
                onClick={() => window.open(LINK.grohe, '_black')}
              >
                <img className='w-28 lg:w-36' src='/images/aboutUs/grohe.png' alt='grohe' />
              </button>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
}

export default AboutUs;
