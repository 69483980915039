import clsx from 'clsx';
import { memo } from 'react';
import { INPUT_ERROR_MSG } from 'constant/contact';
import { FormInput } from 'types/contact';

function ContentTextarea({ isDirty, errors, register }: FormInput) {
  return (
    <div className='col-span-2 flex flex-col py-2'>
      <textarea
        className={clsx('contact_textarea resize-none', {
          contact_input_error: errors,
        })}
        placeholder='문의 내용'
        {...register('content', {
          required: INPUT_ERROR_MSG.content.required,
          minLength: {
            value: 10,
            message: INPUT_ERROR_MSG.content.minLength,
          },
        })}
      />
      <small
        role='alert'
        className={clsx('invisible mt-1 text-red xs:text-xs sm:text-sm', {
          contact_error: errors,
        })}
      >
        {errors ? errors.message : '.'}
      </small>
    </div>
  );
}
export default memo(ContentTextarea);
