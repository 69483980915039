import { AppDispatch, RootState } from 'config/store';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export default function useAppSelector(
//   fn: (state: RootState) => TypedUseSelectorHook<RootState>
// ): TypedUseSelectorHook<RootState> {
//   return useSelector(fn, shallowEqual);
// }
